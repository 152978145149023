import React from 'react';

const SmoothScrollLink = ({ to, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    const targetElement = document.querySelector(to);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
};

export default SmoothScrollLink;
