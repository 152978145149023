import SmoothScrollLink from "./smoothscroll";
import React, { useEffect, useState } from "react";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const text = "ABOUT";
const Navbar = () => {
  const [displayedText, setDisplayedText] = useState(text);
  const [hasHovered, setHasHovered] = useState(false);

  // useEffect(() => {
  //   if (hasHovered === true) {
  //     let iteration = 0;
  //     const intervalId = setInterval(() => {
  //       setDisplayedText((prev) =>
  //         prev
  //           .split("")
  //           .map((letter, index) =>
  //             index < iteration
  //               ? text[index]
  //               : letters[Math.floor(Math.random() * 26)]
  //           )
  //           .join("")
  //       );
  //       if (iteration >= text.length) clearInterval(intervalId);
  //       iteration += 1 / 5;
  //     }, 30);
  //   }
  // }, [hasHovered]);

  return (
    <nav>
      <div className="navbar">
        <a href="/">
          <div className="nav-logo">RM</div>
        </a>
        <ul className="nav-links">
          <li
          // onMouseEnter={() => setHasHovered(true)}
          // onMouseLeave={() => setHasHovered(false)}
          >
            <SmoothScrollLink to="#about">ABOUT</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="#projects">PROJECTS</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="#about">CONTACT</SmoothScrollLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
